// import logo from "./logo.svg";
// import './App.css';

import { useState } from "react";

function App() {
  const [processor, setProcessor] = useState({
    isLoading: false,
    isError: false,
  });
  const [data, setData] = useState({
    //file: null,
    base64: null,
  });
  const [result, setResult] = useState(null);
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    console.log(e.target.files[0]);

    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        setData({
          base64: result.replace("data:application/pdf;base64,", ""),
          //file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // setData({
    //   file: e.target.files[0],
    // });
  };

  const process = () => {
    setResult(null);
    setProcessor({
      ...processor,
      isLoading: true,
      isError: false,
    });

    console.log(window);
    const endpoint =
      window.location.origin.indexOf("localhost") === -1
        ? "https://us-central1-ai-document-366012.cloudfunctions.net/gcp-backend-func"
        : "http://localhost:8080";

    fetch(`${endpoint}/process-document`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(function (res) {
        const { status } = res;

        switch (status) {
          case 200: {
            return res.json();
            break;
          }
          default: {
            throw new Error({ res, status }, "Server Error");
          }
        }
      })
      .then(function (res) {
        console.log("res", res);
        setResult(res);
        setProcessor({
          ...processor,
          isLoading: false,
        });
      })
      .catch(function (err) {
        console.log("err", err);
        setProcessor({
          ...processor,
          isLoading: false,
          isError: true,
        });
      });
  };
  return (
    <>
      <div class="container theme-showcase" role="main">
        <h4>Invoice processing AI - ePROMIS</h4>
        <form>
          <div className="form-group">
            <label htmlFor="exampleFormControlFile1">Invoice</label>
            <input
              type="file"
              className="form-control-file"
              id="exampleFormControlFile1"
              onChange={handleFileInputChange}
            />
          </div>
          {processor.isLoading ? (
            <button type="button" class="btn btn-primary">
              Processing...
            </button>
          ) : (
            <button type="button" class="btn btn-primary" onClick={process}>
              Process
            </button>
          )}
          {processor.isError ? (
            <div className="alert alert-danger" style={{ marginTop: "5px" }}>
              Error processing document
            </div>
          ) : null}
        </form>
        {result ? (
          <div className="row" style={{ marginTop: "5px" }}>
            <div className="col-4">
              <h4>Results</h4>
              <ul class="list-group">
                <li class="list-group-item">
                  Supplier name: {result.supplier_name || null}
                </li>
                <li class="list-group-item">
                  Total amount: {result.total_amount || null}
                </li>
                <li class="list-group-item">
                  Invoice date: {result.invoice_date || null}
                </li>
              </ul>

              <h4>All fields</h4>
              <ul class="list-group">
                {Object.keys(result).map((resultkey, index) => (
                  <li class="list-group-item" key={index}>
                    {resultkey}: {result[resultkey]}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default App;
